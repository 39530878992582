export const sansFamily =
  "'IBM Plex Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'NotoColorEmoji', 'Segoe UI Symbol', 'Android Emoji', 'EmojiSymbols'";
export const defaultColor = `var(--color-primary-text-on-light, #2b313b)`;
const fontWeight = {
  bold: "var(--font-weight-bold)",
  medium: "var(--font-weight-medium)",
  regular: "var(--font-weight-regular)",
};
const fontWeightWithSemiBold = {
  bold: "var(--font-weight-semi-bold)",
  medium: "var(--font-weight-medium)",
};
const fontWeightWithoutBold = {
  medium: "var(--font-weight-medium)",
  regular: "var(--font-weight-regular)",
};
const fontWeightOnlyRegular = {
  regular: "var(--font-weight-regular)",
};
export const variantMap = {
  "title-xl": {
    component: "h1",
    fontWeight,
    style: {
      fontSize: "var(--font-size-title-xl)",
      letterSpacing: "-2px",
      lineHeight: "1.25",
    },
  },
  "title-lg": {
    component: "h2",
    fontWeight,
    style: {
      fontSize: "var(--font-size-title-lg)",
      letterSpacing: "-1.5px",
      lineHeight: "1.3333",
    },
  },
  "title-md": {
    component: "h3",
    fontWeight,
    style: {
      fontSize: "var(--font-size-title-md)",
      letterSpacing: "-1.5px",
      lineHeight: "1.25",
    },
  },
  "title-sm": {
    component: "h4",
    fontWeight,
    style: {
      fontSize: "var(--font-size-title-sm)",
      letterSpacing: "-0.5px",
      lineHeight: "1.3333",
    },
  },
  "subtitle-lg": {
    component: "h5",
    fontWeight,
    style: {
      font: "var(--font-subtitle-lg)",
    },
  },
  "subtitle-sm": {
    component: "h6",
    fontWeight,
    style: {
      font: "var(--font-subtitle-sm)",
    },
  },
  "body-lg": {
    component: "p",
    fontWeight: fontWeightWithSemiBold,
    style: {
      font: "var(--font-body-lg)",
      letterSpacing: "0px",
    },
  },
  "body-sm": {
    component: "p",
    fontWeight: fontWeightWithoutBold,
    style: {
      font: "var(--font-body-sm)",
    },
  },
  "caption-lg": {
    component: "p",
    fontWeight: fontWeightWithoutBold,
    style: {
      font: "var(--font-caption-lg)",
    },
  },
  "caption-lg-loose": {
    component: "p",
    fontWeight: fontWeightWithoutBold,
    style: {
      font: "var(--font-caption-lg)",
      lineHeight: "1.666",
    },
  },
  "caption-sm": {
    component: "p",
    fontWeight: fontWeightWithoutBold,
    style: {
      font: "var(--font-caption-sm)",
    },
  },
  "code-lg": {
    component: "code",
    fontWeight: fontWeightOnlyRegular,
    style: {
      font: "var(--font-code-lg)",
    },
  },
  "code-md": {
    component: "code",
    fontWeight: fontWeightOnlyRegular,
    style: {
      font: "var(--font-code-md)",
    },
  },
  "code-sm": {
    component: "code",
    fontWeight: fontWeightOnlyRegular,
    style: {
      font: "var(--font-code-sm)",
    },
  },
  "glyph-xl": {
    component: "span",
    fontWeight: fontWeightOnlyRegular,
    style: {
      fontSize: "var(--font-size-title-sm)",
      lineHeight: "1.333",
    },
  },
  "glyph-lg": {
    component: "span",
    fontWeight: fontWeightOnlyRegular,
    style: {
      fontSize: "var(--font-size-subtitle-lg)",
      lineHeight: "1.4",
    },
  },
  "glyph-md": {
    component: "span",
    fontWeight: fontWeightOnlyRegular,
    style: {
      fontSize: "var(--font-size-body-lg)",
      lineHeight: "1.5",
    },
  },
  "glyph-sm": {
    component: "span",
    fontWeight: fontWeightOnlyRegular,
    style: {
      fontSize: "var(--font-size-body-sm)",
      lineHeight: "1.428",
    },
  },
} as {
  [key: string]: {
    component: string;
    fontWeight: Record<string, string>;
    style: Record<string, string>;
  };
};
