import { jsx as c } from "react/jsx-runtime";
const o = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M12 20c.717 0 1.417-.096 2.1-.288a8.06 8.06 0 0 0 1.95-.837L12.175 15H12c-.9 0-1.783.117-2.65.35a9.903 9.903 0 0 0-2.475 1.045.892.892 0 0 0-.462.744c-.025.33.079.612.312.843a8.66 8.66 0 0 0 2.45 1.48c.9.359 1.842.538 2.825.538Zm7.775 2.575-2.25-2.225c-.817.533-1.692.942-2.625 1.225-.933.283-1.9.425-2.9.425a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-.983.138-1.942.413-2.875a10.29 10.29 0 0 1 1.212-2.65L1.375 4.2a.93.93 0 0 1-.287-.713 1.02 1.02 0 0 1 .312-.712c.2-.2.438-.3.713-.3.275 0 .512.1.712.3l18.375 18.4c.2.2.3.433.3.7 0 .267-.1.5-.3.7-.2.2-.438.3-.712.3a.973.973 0 0 1-.713-.3Zm.3-5.875a1.3 1.3 0 0 1-.475-.087 1.24 1.24 0 0 1-.425-.288L15.1 12.25a.684.684 0 0 1-.213-.463.899.899 0 0 1 .138-.512 2.94 2.94 0 0 0 .362-.85A3.8 3.8 0 0 0 15.5 9.5c0-.967-.342-1.792-1.025-2.475A3.372 3.372 0 0 0 12 6a3.44 3.44 0 0 0-.925.125c-.3.083-.583.2-.85.35a1.134 1.134 0 0 1-.512.125.635.635 0 0 1-.463-.2L7.675 4.825a1.24 1.24 0 0 1-.287-.425 1.299 1.299 0 0 1-.088-.475c0-.233.054-.446.163-.637a1.03 1.03 0 0 1 .512-.438 9.643 9.643 0 0 1 1.963-.638A10.014 10.014 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12c0 .7-.07 1.387-.212 2.063a9.646 9.646 0 0 1-.638 1.962 1.03 1.03 0 0 1-.438.513 1.273 1.273 0 0 1-.637.162Z" })
  }
);
export {
  o as default
};
