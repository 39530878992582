import { jsx as a } from "react/jsx-runtime";
const s = (c) => /* @__PURE__ */ a(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...c,
    children: /* @__PURE__ */ a("path", { d: "M9.8 21.752c-2.283-.517-4.154-1.684-5.613-3.5C2.73 16.435 2 14.352 2 12.002s.73-4.434 2.188-6.25c1.458-1.817 3.329-2.984 5.612-3.5a.888.888 0 0 1 .837.175c.242.2.363.466.363.8 0 .233-.067.441-.2.625a.884.884 0 0 1-.55.35c-1.833.416-3.333 1.35-4.5 2.8-1.167 1.45-1.75 3.116-1.75 5 0 1.883.583 3.55 1.75 5 1.167 1.45 2.667 2.383 4.5 2.8.233.05.417.166.55.35.133.183.2.391.2.625 0 .333-.12.6-.363.8a.889.889 0 0 1-.837.175Zm4.4 0a.889.889 0 0 1-.837-.175.986.986 0 0 1-.363-.8c0-.234.067-.442.2-.625a.884.884 0 0 1 .55-.35c.45-.1.887-.238 1.313-.413.425-.175.829-.387 1.212-.637a.804.804 0 0 1 .613-.15c.224.033.42.133.587.3a.957.957 0 0 1 .3.812.99.99 0 0 1-.45.738c-.483.3-.988.558-1.512.775a11.03 11.03 0 0 1-1.613.525Zm4.7-4.25a1.025 1.025 0 0 1-.3-.588.804.804 0 0 1 .15-.612 7.11 7.11 0 0 0 .637-1.225c.175-.434.313-.875.413-1.325a.835.835 0 0 1 .35-.538c.183-.125.392-.187.625-.187.333 0 .6.12.8.362.2.242.258.52.175.838a11.03 11.03 0 0 1-.525 1.612c-.217.525-.475 1.03-.775 1.513a.99.99 0 0 1-.738.45.957.957 0 0 1-.812-.3Zm1.875-6.5c-.233 0-.442-.067-.625-.2a.884.884 0 0 1-.35-.55c-.1-.45-.238-.888-.413-1.313a7.309 7.309 0 0 0-.637-1.212.883.883 0 0 1-.15-.638c.033-.225.133-.42.3-.587a.957.957 0 0 1 .813-.3c.308.033.554.191.737.475.3.483.558.987.775 1.512.217.525.392 1.063.525 1.613a.889.889 0 0 1-.175.837.986.986 0 0 1-.8.363Zm-4.5-5.75a7.307 7.307 0 0 0-1.212-.638 7.997 7.997 0 0 0-1.313-.412.884.884 0 0 1-.55-.35 1.036 1.036 0 0 1-.2-.625c0-.334.12-.6.363-.8.241-.2.52-.259.837-.175.55.133 1.088.308 1.613.525.524.216 1.029.475 1.512.775.283.183.442.429.475.737a.957.957 0 0 1-.3.813 1.026 1.026 0 0 1-.587.3.883.883 0 0 1-.638-.15Z" })
  }
);
export {
  s as default
};
