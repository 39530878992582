import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { IconButton, ButtonPrimary } from "@happeouikit/buttons";
import { IconMoreVert } from "@happeouikit/icons";
import DropdownActionMenu from "./DropdownActionMenu";
import { uuidv4 } from "./utils";
import { Wrapper } from "./styles";

import { ActionsMenuProps } from "./ActionsMenu.types";

const ActionsMenu = ({
  actions,
  selectedActionName,
  menuPosition = "left",
  text,
  ariaLabel = "actions-button",
  icon,
  iconPosition,
  button,
  size,
  autoHide,
  disabled,
  id,
  ...props
}: ActionsMenuProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const dropDownMenuRef = useRef<HTMLElement>();

  let menuTimer: NodeJS.Timeout;
  const [isOpen, setIsOpen] = useState(false);
  const MenuButton = button || (text && ButtonPrimary);
  const actionMenuId = useMemo(() => id || uuidv4(), [id]);
  const dropdownId = useMemo(() => props.dropdownId || uuidv4(), [
    props.dropdownId,
  ]);

  useEffect(() => {
    return () => {
      if (autoHide) {
        clearTimeout(menuTimer);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openMenu = () => {
    if (!isOpen) {
      setIsOpen(true);

      setTimeout(() => {
        const menu = document.getElementById(dropdownId);
        // @ts-ignore
        menu?.querySelector('[role="menuitem"]')?.focus();
      }, 1);
    } else {
      setIsOpen(false);
    }
  };

  const closeMenu = useCallback(() => {
    setIsOpen(false);
    ref.current?.querySelector("button")?.focus();
  }, [ref]);

  const onKeyDown = (event: any) => {
    if (event.key.match(/esc|escape/i)) {
      event.preventDefault();
      if (isOpen) {
        event.stopPropagation();
        setIsOpen(false);
        ref.current?.querySelector("button")?.focus();
      }
    } else if (event.key.match(/arrowdown/i)) {
      event.preventDefault();
      if (isOpen) {
        const menu = document.getElementById(dropdownId);
        // @ts-ignore
        menu?.querySelector('[role="menuitem"]')?.focus();
      } else {
        setIsOpen(true);
      }
    }
  };

  return (
    <Wrapper
      /* eslint-disable-next-line react/destructuring-assignment */
      data-tracker={props["data-tracker"]}
      onPointerEnter={() => {
        if (autoHide) {
          clearTimeout(menuTimer);
        }
      }}
      onPointerLeave={() => {
        if (autoHide) {
          menuTimer = setTimeout(() => setIsOpen(false), 500);
        }
      }}
      ref={ref}
      onKeyDown={onKeyDown}
    >
      {MenuButton ? (
        <MenuButton
          id={actionMenuId}
          tabIndex="0"
          aria-label={ariaLabel || text}
          aria-haspopup
          aria-controls={dropdownId}
          aria-expanded={isOpen}
          disabled={disabled}
          text={text}
          icon={icon}
          size={size}
          iconPosition={iconPosition}
          onClick={openMenu}
        />
      ) : (
        <IconButton
          id={actionMenuId}
          tabIndex="0"
          aria-haspopup
          aria-label={ariaLabel}
          aria-controls={dropdownId}
          aria-expanded={isOpen}
          disabled={disabled}
          isActionIcon
          icon={icon || (() => <IconMoreVert width="20px" height="20px" />)}
          onClick={openMenu}
        />
      )}
      {isOpen && (
        <DropdownActionMenu
          ref={dropDownMenuRef}
          anchor={ref}
          dropdownId={dropdownId}
          actions={actions}
          selectedActionName={selectedActionName}
          menuPosition={menuPosition}
          onClose={closeMenu}
        />
      )}
    </Wrapper>
  );
};

ActionsMenu.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      callback: PropTypes.func,
      url: PropTypes.string,
      type: PropTypes.string,
      icon: PropTypes.func,
      "data-tracker": PropTypes.string,
    })
  ).isRequired,
  selectedActionName: PropTypes.string,
  menuPosition: PropTypes.string,
  button: PropTypes.elementType,
  text: PropTypes.string,
  icon: PropTypes.func,
  iconPosition: PropTypes.string,
  size: PropTypes.string,
  autoHide: PropTypes.bool,
  dropdownId: PropTypes.string,
  ariaLabel: (props: any, propName: string) => {
    if (!props[propName]) {
      return new Error(
        "Provide an ariaLabel prop to the ActionsMenu component. Ensure that is describes what this button does."
      );
    }
    return null;
  },
};

ActionsMenu.defaultProps = {
  menuPosition: "left",
  iconPosition: "left",
  autoHide: false,
  size: null,
  button: null,
  icon: null,
  text: "",
};

export default ActionsMenu;
