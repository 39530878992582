import styled, { css } from "styled-components";
import { BaseProps, TextGammaProps, TextThetaProps } from "./Typography.types";

export const sansFamily =
  "'IBM Plex Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'NotoColorEmoji', 'Segoe UI Symbol', 'Android Emoji', 'EmojiSymbols'";
export const defaultColor = `var(--color-primary-text-on-light, #2b313b)`;
const fontWeight = {
  bold: "500",
  regular: "400",
};

/**
 * Apply correct font-weight font
 * @param {Boolean} bold - shows whether the text should be bold or not
 */
function fW(bold?: boolean) {
  return `font-weight: ${bold ? fontWeight.bold : fontWeight.regular};`;
}

const baseStyles = css<BaseProps>`
  font-family: ${sansFamily};
  margin: 0;
  color: ${({ color }) => color || defaultColor};
  -webkit-font-smoothing: antialiased;
  ${({ bold }) => fW(bold)};
`;

/**
 * @deprecated use instead TitleXl
 */
export const TextMega = styled.h1`
  ${baseStyles};
  font-size: 64px;
  letter-spacing: -2px;
  line-height: 80px;
`;

/**
 * @deprecated use instead TitleLg
 */
export const TextAlpha = styled.h1`
  ${baseStyles};
  font-size: 48px;
  letter-spacing: -1.5px;
  line-height: 64px;
`;

/**
 * @deprecated use instead TitleMd
 */
export const TextBeta = styled.h1`
  ${baseStyles};
  font-size: 32px;
  letter-spacing: -0.75px;
  line-height: 40px;
`;

/**
 * @deprecated use instead TitleSm
 */
export const TextGamma = styled.h2<TextGammaProps>`
  ${baseStyles};
  font-size: 24px;
  ${({ bold }) => fW(bold)};
  letter-spacing: -0.5px;
  line-height: 32px;
`;

/**
 * @deprecated use instead SubtitleLg
 */
export const TextDelta = styled.h2`
  ${baseStyles};
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0;
`;

/**
 * @deprecated use instead SubtitleSm
 */
export const TextEpsilon = styled.p`
  ${baseStyles};
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0;
`;

/**
 * @deprecated use instead BodyLg
 */
export const TextZeta = styled.p`
  ${baseStyles};
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
`;

/**
 * @deprecated use instead BodySm
 */
export const TextEta = styled.p`
  ${baseStyles};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;

/**
 * @deprecated use instead CaptionLg
 */
export const TextTheta = styled.p<TextThetaProps>`
  ${baseStyles};
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "normal")};
`;

/**
 * @deprecated use instead BodyLg
 */
export const BodyUI = TextEta;
/**
 * @deprecated use instead CaptionLg
 */
export const TinyText = TextTheta;
