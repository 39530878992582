import { jsx as c } from "react/jsx-runtime";
const s = (a) => /* @__PURE__ */ c(
  "svg",
  {
    width: 24,
    height: 24,
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg",
    ...a,
    children: /* @__PURE__ */ c("path", { d: "M12 20c.85 0 1.667-.13 2.45-.387a8.284 8.284 0 0 0 2.2-1.113 8.284 8.284 0 0 0-2.2-1.113A7.773 7.773 0 0 0 12 17c-.85 0-1.667.13-2.45.387a8.284 8.284 0 0 0-2.2 1.113 8.284 8.284 0 0 0 2.2 1.113c.783.258 1.6.387 2.45.387Zm2.475-12.975c.517.517.842 1.133.975 1.85a3.42 3.42 0 0 1-.25 2.075c-.133.283-.33.48-.587.588a.905.905 0 0 1-.788-.038.995.995 0 0 1-.525-.563.878.878 0 0 1 .05-.762c.133-.283.175-.583.125-.9a1.465 1.465 0 0 0-.425-.825 1.465 1.465 0 0 0-.763-.412 1.465 1.465 0 0 0-.862.087.892.892 0 0 1-.7-.037.874.874 0 0 1-.475-.563.957.957 0 0 1 .063-.813c.141-.258.354-.445.637-.562a2.879 2.879 0 0 1 1.838-.1 3.648 3.648 0 0 1 1.687.975ZM12 22a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-.983.138-1.942.413-2.875a10.29 10.29 0 0 1 1.212-2.65L1.375 4.2a.93.93 0 0 1-.287-.713 1.02 1.02 0 0 1 .312-.712c.2-.2.438-.3.713-.3.275 0 .512.1.712.3l18.375 18.4c.2.2.296.438.288.712a1.02 1.02 0 0 1-.313.713c-.2.2-.433.3-.7.3a.96.96 0 0 1-.7-.3L5.1 7.95a7.707 7.707 0 0 0-.825 1.95A8.068 8.068 0 0 0 4 12a7.878 7.878 0 0 0 1.85 5.1 10.37 10.37 0 0 1 2.913-1.563A9.884 9.884 0 0 1 12 15c.633 0 1.267.067 1.9.2s1.25.317 1.85.55l3.325 3.325a9.858 9.858 0 0 1-3.25 2.175c-1.217.5-2.492.75-3.825.75ZM7.85 2.9c.65-.3 1.325-.525 2.025-.675C10.575 2.075 11.283 2 12 2c1.333 0 2.608.25 3.825.75s2.3 1.225 3.25 2.175a9.858 9.858 0 0 1 2.175 3.25c.5 1.217.75 2.492.75 3.825 0 .717-.075 1.425-.225 2.125-.15.7-.375 1.375-.675 2.025a.865.865 0 0 1-.575.488.941.941 0 0 1-.75-.088 1.012 1.012 0 0 1-.475-.6 1.11 1.11 0 0 1 .05-.8A8.022 8.022 0 0 0 20 12c0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4a8.02 8.02 0 0 0-1.6.162c-.533.109-1.05.271-1.55.488a1.11 1.11 0 0 1-.8.05 1.012 1.012 0 0 1-.6-.475.941.941 0 0 1-.087-.75.865.865 0 0 1 .487-.575Z" })
  }
);
export {
  s as default
};
