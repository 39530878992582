import styled from "styled-components";
import { TypographyProps } from "./Typography.types";
import { defaultColor, variantMap, sansFamily } from "./constants";

export const Typography = ({
  variant = "body-lg",
  fontWeight: fw = "regular",
  component,
  bold = false,
  uppercase = false,
  color = defaultColor,
  style,
  children,
  ...props
}: React.PropsWithChildren<TypographyProps>) => {
  const variantToUse = variantMap[variant] ? variant : "body-lg";
  const {
    fontWeight: defaultFontWeight,
    component: defaultComponent,
  } = variantMap[variantToUse];
  const fwToUse = bold ? "bold" : fw;
  const fontWeight = defaultFontWeight[fwToUse] || defaultFontWeight.regular;
  return (
    <BaseTypography
      $weight={fontWeight}
      $color={color}
      $variant={variant}
      $uppercase={uppercase}
      as={component || defaultComponent}
      style={style}
      {...props}
    >
      {children}
    </BaseTypography>
  );
};

const BaseTypography: React.FC<{
  as: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  $variant: string;
  $weight: string;
  $color: string;
  $uppercase: boolean;
}> = styled.p<{
  $variant: string;
  $weight: string;
  $color: string;
  $uppercase: boolean;
}>`
  -webkit-font-smoothing: antialiased;
  ${({ $variant, $weight, $color, $uppercase }) => ({
    ...variantMap[$variant].style,
    fontWeight: $weight,
    color: $color || defaultColor,
    textTransform: $uppercase ? "uppercase" : "inherit",
    fontFamily: sansFamily,
  })};
`;

export const TitleXl = (props: React.PropsWithChildren<TypographyProps>) => (
  <Typography {...props} variant="title-xl" />
);
export const TitleLg = (props: React.PropsWithChildren<TypographyProps>) => (
  <Typography {...props} variant="title-lg" />
);
export const TitleMd = (props: React.PropsWithChildren<TypographyProps>) => (
  <Typography {...props} variant="title-md" />
);
export const TitleSm = (props: React.PropsWithChildren<TypographyProps>) => (
  <Typography {...props} variant="title-sm" />
);
export const SubtitleLg = (props: React.PropsWithChildren<TypographyProps>) => (
  <Typography {...props} variant="subtitle-lg" />
);
export const SubtitleSm = (props: React.PropsWithChildren<TypographyProps>) => (
  <Typography {...props} variant="subtitle-sm" />
);
export const BodyLg = (props: React.PropsWithChildren<TypographyProps>) => (
  <Typography {...props} variant="body-lg" />
);
export const BodySm = (props: React.PropsWithChildren<TypographyProps>) => (
  <Typography {...props} variant="body-sm" />
);
export const CaptionLg = (props: React.PropsWithChildren<TypographyProps>) => (
  <Typography {...props} variant="caption-lg" />
);
export const CaptionLgLoose = (
  props: React.PropsWithChildren<TypographyProps>
) => <Typography {...props} variant="caption-lg-loose" />;
export const CaptionSm = (props: React.PropsWithChildren<TypographyProps>) => (
  <Typography {...props} variant="caption-sm" />
);
export const CodeLg = (props: React.PropsWithChildren<TypographyProps>) => (
  <Typography {...props} variant="code-lg" />
);
export const CodeMd = (props: React.PropsWithChildren<TypographyProps>) => (
  <Typography {...props} variant="code-md" />
);
export const CodeSm = (props: React.PropsWithChildren<TypographyProps>) => (
  <Typography {...props} variant="code-sm" />
);
export const GlyphXl = (props: React.PropsWithChildren<TypographyProps>) => (
  <Typography {...props} variant="glyph-xl" />
);
export const GlyphLg = (props: React.PropsWithChildren<TypographyProps>) => (
  <Typography {...props} variant="glyph-lg" />
);
export const GlyphMd = (props: React.PropsWithChildren<TypographyProps>) => (
  <Typography {...props} variant="glyph-md" />
);
export const GlyphSm = (props: React.PropsWithChildren<TypographyProps>) => (
  <Typography {...props} variant="glyph-sm" />
);
